'use strict';
const $ = require('jquery');
window.$ = $;
window.jQuery = $;
require('bootstrap');

import { backButton, cookiesBar } from './modules'
import form from "./forms/form"

import Swiper, { Navigation } from 'swiper';
import 'swiper/swiper-bundle.css';
Swiper.use([Navigation]);

import adminMenu from '@elasticms/admin-menu';
import '../css/app.scss';

adminMenu();
form()

let updateURLActive = false;

backButton.init('.js-back-button');
cookiesBar.init('.js-cookies-bar');

(function() {
    $(document).ready(function() {
        if ($('.search-results').length > 0) {
            //check url
            const url = new URL(window.location);
            const index = url.searchParams.get('what');
            const forParams = url.searchParams.getAll('for');

            //set first select
            if (index) {
                $('.dropdown-action .dropdown-item[data-index="'+ index +'"]').trigger('click');
            }

            //set second select
            forParams.forEach(function(forParam) {
                $('input[id="'+ forParam +'"]').prop('checked', true);
            });

            //handle the selected jobs (ajax request)
            jobHandler();
            //allow to update the URL again after we parsed it (otherwise, updates made when parsing would mess up the URL)
            updateURLActive = true;
        }

        const $backToSearch = $('.back-to-search')
        if ($backToSearch.length > 0 && window.location.search.indexOf('h=1') >= 0) {
            let backURL = localStorage.getItem('history');
            if (backURL) {
                $backToSearch.attr('href', backURL);
            }
        }
    });

    new Swiper('#news-swiper', {
        slidesPerView: 1,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        spaceBetween: 30
    });

    new Swiper('#quick-access-swiper', {
        slidesPerView: 1,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        spaceBetween: 30
    });

    let collapseCounter = 0;
    $('.wysiwyg-content .collapse-item').each(function() {
        let $toggle = $(this).children().first();
        let $content = $(this).children().not(':first-child');

        let $newToggle = $('<a href="#collapse-'+ collapseCounter +'" class="text-decoration-none text-body" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="collapse-'+ collapseCounter +'">'+ $toggle.text() + '</a>');
        $toggle.html($newToggle);

        let $newContentBody = $('<div class="collapse-body"></div>');
        $newContentBody.append($content);
        let $newContent = $('<div class="collapse" id="collapse-'+ collapseCounter +'"></div>');
        $newContent.append($newContentBody);

        $newContent.insertAfter($toggle);

        collapseCounter++;
    });

    $(document).on('click', '.pro-filter .keep-open', function(e) {
        e.stopPropagation();
    });

    $(document).on('change', '#profession_all', function() {
        let $checkboxes = $(this).closest('.dropdown-menu').find('input[type="checkbox"]:not(#profession_all)');
        $checkboxes.prop('checked', $(this).is(':checked'));
        $checkboxes.first().trigger('change');
    });

    $(document).on('click', '.dropdown-action .dropdown-item', function() {
        let $dropdownButtonProfession = $('#dropdownMenuProfession');
        $dropdownButtonProfession.data('placeholder')
        $dropdownButtonProfession.addClass('disabled');

        let $dropdownButton = $('#dropdownMenuAction');
        $dropdownButton.text($(this).text());

        let index = $(this).data('index');
        const $dropdown_profession = $('.dropdown-profession')
        $dropdown_profession.data('index', index);

        let prototype = $dropdown_profession.data('prototype');
        let $dropdownPlaceholder = $('.dropdown-profession .scrollable');
        $dropdownPlaceholder.empty();
        $('#selector_results').empty();
        $dropdownButtonProfession.text($dropdownButtonProfession.data('placeholder'));

        for (let i = 0; i < jobs[index].length; i++) {
            let job = jobs[index][i];
            let jobHtml = prototype.replace(/__OUUID__/g, job.ouuid).replace(/__TITLE__/g, job.title);

            $dropdownPlaceholder.append(jobHtml);
        }
        $('#profession_all').prop('checked', false);
        $dropdownButtonProfession.removeClass('disabled');

        if (updateURLActive) {
            updateURL();
        }
    });

    function jobHandler() {
        let $selectedCheckboxes = $('.dropdown-profession input[type="checkbox"]:not(#profession_all):checked');
        let selected = $selectedCheckboxes.length;
        let $dropdownButton = $('#dropdownMenuProfession');

        let $loading = $('.pro-filter .loading');

        if (selected === 0) {
            $dropdownButton.text($dropdownButton.data('placeholder'));
        } else if (selected === 1) {
            $dropdownButton.text($selectedCheckboxes.next().text());
        } else {
            $dropdownButton.text($dropdownButton.data('placeholder-selected-plural').replace('%d', selected));
        }
        const $dropdownProfession = $('.dropdown-profession')
        let url = $dropdownProfession.data('url');
        let index = $dropdownProfession.data('index');

        url = url.replace('__CHANGEME__', index);

        let professionsIds = [];
        $selectedCheckboxes.each(function() {
            professionsIds.push($(this).attr('id'));
        });

        if (selected > 0) {
            $loading.show();
            $.get(url, {id: professionsIds}, function(response) {
                $loading.hide();
                const $selectorResults = $('#selector_results')
                $selectorResults.empty();
                $selectorResults.append(response.html);
                $('#selector_results_number').text($('#selector_results tr').length);

                $('.search-hit-link').each(function() {
                    let href = $(this).attr('href');
                    if (!href.endsWith('?h=1')) {
                        $(this).attr('href', href + '?h=1');
                    }
                });

                // on hold
                // paginateSearchHits();
                adminMenu();
            });
        }

        if (updateURLActive) {
            updateURL();
        }
    }

    // function paginateSearchHits() {
    //     let container = $('.paginate-search-hits');
    // }

    function updateURL() {
        let index = $('.dropdown-profession').data('index');
        let $selectedCheckboxes = $('.dropdown-profession input[type="checkbox"]:not(#profession_all):checked');

        const url = new URL(window.location);
        url.searchParams.set('what', index);

        url.searchParams.delete('for');
        if ($selectedCheckboxes.length) {
            $selectedCheckboxes.each(function() {
                url.searchParams.append('for', $(this).attr('id'));
            });
        }

        window.history.pushState({}, '', url);
        localStorage.setItem('history', url);
    }

    $(document).on('change', '.dropdown-profession input[type="checkbox"]:not(#profession_all)', jobHandler);
})();
